@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Inter', 'DM Sans' sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
}

.custom-scrollbar ::-webkit-scrollbar-track {
    background-color: transparent;
}

.custom-scrollbar ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.custom-scrollbar ::-webkit-scrollbar {
    width: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

.alert-bounce-warning .icon{
  color: #FFF;
}

.alert-bounce-warning .icon{
  background: #F89F64;
  border-radius: 6px;
  margin-right: 12px;
  padding: 8px;
}
.alert-bounce-warning{
  background: #FEF5F0 !important;
  border: 1px solid #F89F64 !important;
}

.alert-bounce{
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

input[type="checkbox"] + label {
  cursor: pointer;
}

.separate > input{
    margin-right: 15px;
}

.bg-gray{
    background-color: #e1e1e1 !important;
}

body,
html {
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
  background: #f9fafb;
  border-radius: 48px;
  bottom: 0;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.menu {
  padding: 0;
  list-style-type: none;
}
.menu.isFlatMenu {
  padding: 0;
  list-style-type: none;
  text-align: left;
}
.menu-item {
  height: auto;
  min-height: 42px;
  max-height: 84px;
  background-color: var(--easter-blue-200);
  border-radius: 8px;
  padding: 8px 8px 8px 12px;
  margin: 9px 0;
}

li.menu-item:hover{
  border: 1px solid var(--easter-blue-500);
}

li.menu-item{
  border: 1px solid transparent;
}

.menu-item-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: var(--easter-blue-500);
}
.menu-item.expanded {
  background-color: var(--easter-blue-400);
}
.menu-item.expanded.isFlatMenu .menu-item-title {
  background-color: white;
  color: #282c34;
}
.menu-item.isFlatMenu {
  background-color: white;
}
.menu-item.isFlatMenu .menu-item-title {
  color: #282c34;
}
.menu-item.expanded .menu-item-title {
  color: #ffffff;
}
.menu-item.hasSubmenu > .menu-item-title {
  font-weight: bold;
}
.menu .menu .menu-item.hasSubmenu > .menu-item-title {
  font-weight: normal;
}
.menu-item.hasSubmenu.expanded.isModuleOpen {
  background-color: var(--tan-hide-500);
}
.menu.isModuleOpen {
  border-left: 3px solid var(--tan-hide-500);
}
.menu.isModuleOpen > .menu-item {
  padding: 8px 8px 8px 28px;
  /*height: 40px;*/
  height: auto;
  /* Secondary / Eastern-Blue / 100 */
  background: var(--easter-blue-100);
}
.menu-item.hasSubmenu.isFlatMenu > .menu-item-title {
  /* Body/Regular / 16px */
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
}
.menu-item.expanded.hasSubmenu.isSectionOpen {
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
  background-color: var(--easter-blue-400);
  border-left:none;
}

.hasSubmenu > .menu-item-title::after {
  content: " + ";
  padding: 0 12px;
  float: right;
  font-size: 21px;
}

.expanded.hasSubmenu > .menu-item-title::after {
  content: " — ";
  padding: 0 12px;
  float: right;
  font-size: 21px;
}
.menu .menu {
  padding-left: 0;
  margin-left: 8px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.custom-button-default:hover {
  background: rgb(241, 137, 67);
}

.custom-button-default {
  /* Body/Semi Bold / 16px */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #f89f64;
  border-radius: 6px;
  border: 0px;
  padding: 12px;
  width: 100%;
  height: 48px;
  position: relative;
}
.b-custom-button-default {
  /* Body/Semi Bold / 16px */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #f89f64;
  border-radius: 6px;
  border: 0px;
  padding: 12px;
  height: 48px;
}

.custom-button-default.disabled,
.b-custom-button-default.disabled {
  background: #fae3d3;
}
.custom-button-white {
  /* Body/Semi Bold / 16px */
  background: #ffffff;
  /* Gray / Cool Gray / 200 */
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  /* Modal - shadow */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.custom-button-white img {
  padding: 0 10px;
}
.custom-button-blue {
  /* Secondary / Eastern-Blue / 100 */
  width: 100%;
  background: #e9f5f8;
  border-radius: 8px;
  border: 0px;
  padding: 8px 16px;

  /* Body/Semi Bold / 16px */

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  /* Secondary / Eastern-Blue / 800 */

  color: #155e6d;
}

.custom-button-danger {
  /* Body/Semi Bold / 16px */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #f0494a;
  border-radius: 6px;
  border: 0px;
  padding: 16px;
}

.custom-label-text {
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #111827;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.custom-label-description {
  position: static;
  width: 366px;
  height: 20px;
  left: 80px;
  top: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #1f2937;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 12px;
}

.custom-question-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: center;
  flex-direction: row;
  align-items: center;
  padding: 8px;
}
.custom-label-question {
  color: #1f2937;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.custom-label-question-number {
  color: #9ca3af;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.custom-button-back svg{
  margin-right: 10px;
}

.custom-button-back:hover {
  background-color: #f6f5f5;
}

.custom-button-back {
  /*height: 48px;*/
  left: 130px;
  right: 385px;
  top: 201px;

  /* Gray / Cool Gray / 100 */

  /*background: #f3f4f6;*/
  background: #fff;
  /* Black / Dark Black / 800 */

  border: 1px solid #121212;
  box-sizing: border-box;
  border-radius: 6px;

  padding: 11px 25px;
}

.custom-question-answers {
  padding: 0px;
  position: static;
  left: 0px;
  top: 0px;
  background: #f4fafb;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
  display: flex;
}

.custom-submit-container {
  display: flex;
  justify-content: flex-end;
}

.custom-submit-container-modal {
  justify-content: flex-end;
}

.custom-submit-button:hover {
  background: rgb(241, 137, 67);
}

.custom-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 87px;
  height: 48px;
  /* Complement / Tan-Hide / 500 */
  border: none;
  background: #f89f64;
  border-radius: 6px;
  margin-bottom: 0;
  cursor: pointer;
}

.custom-submit-button-retake {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 87px;
  height: 48px;
  /* Complement / Tan-Hide / 500 */
  border: none;
  background: #f89f64;
  border-radius: 6px;
  margin-left: 10px;
}

.custom-submit-button-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  /* Complement / Tan-Hide / 500 */
  border: none;
  background: #f89f64;
  border-radius: 6px;
}

.modal-success-number-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;

  position: static;
  width: 88px;
  height: 48px;
  left: 59px;
  top: 0px;

  background: #dafff1;
  border-radius: 8px;
}
.modal-success-number-text {
  text-align: center;

  color: #13b77b;
}

.modal-fail-number-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;

  position: static;
  width: 88px;
  height: 48px;
  left: 59px;
  top: 0px;

  background: #feeded;
  border-radius: 8px;
}
.modal-fail-number-text {
  text-align: center;

  color: #f0494a;
}
.modal-success-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */
  text-align: center;
  /* gray/900 */
  color: var(--gray-900);
}
.modal-container-success {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  position: static;
}
.custom-submit-button-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
}

.custom-label-button-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  align-items: flex-end;
  position: static;
  width: 68px;
  height: 40px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 12px;
}

input[type="radio"] {
  visibility: hidden;
  position: relative;

  margin-left: 5px;
  width: 20px;
  height: 20px;
}

input[type="radio"]:before {
  content: "";
  visibility: visible;
  content: "";
  background: #ffffff;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

input[type="radio"]:checked:before {
  background-color: #239cb6;
  box-shadow: inset 0 0 0 4px #ffffff;
  border: 1.5px solid #239cb6;
  box-sizing: border-box;
  border-radius: 50px;
}

input[type="checkbox"] {
  visibility: hidden;
  position: relative;

  margin-left: 5px;
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:before {
  content: "";
  visibility: visible;
  content: "";
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

input[type="checkbox"]:checked:before {
  background-color: #239cb6;
  box-shadow: inset 0 0 0 4px #ffffff;
  border: 1.5px solid #239cb6;
  box-sizing: border-box;
  border-radius: 6px;
}

.custom-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-spinner-container span {
  padding-left: 5px;
}

.btn-back span{
  margin-left: 15px;
}

.btn-back{
  border: none;
  background-color: transparent;
}

@media (max-width:250px) {
  .App {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width:320px) {
  .App {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width:480px) {
  .App {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width:600px) {
  .App {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media (min-width:801px) {
}


.bg-transparent{
  background: transparent !important;
}


.loading {
  z-index: 999;
  position: fixed;
  top: 0; right: 0;
  bottom: 0; left: 0;
  background-color: rgba(0,0,0,.3);
}
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: .8em solid rgba(218, 219, 223, 1);
  border-left: .8em solid rgba(58, 166, 165, 1);
  -webkit-animation: spin 1.1s infinite linear;
          animation: spin 1.1s infinite linear;
}
.loader, .loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.App_default-button__2Qnsf {
  height: 40px;
  background-color: #007bff;
  width: 100%;
}
.App_warning-button__11FLm {
  height: 40px;
  background-color: #c72b62;
  width: 100%;
  border: none;
}
.App_terms-wrapper__2jFq6 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 15px 0;
}
.App_terms-and-conditions__ZlAW6 {
  text-align: left;
  padding-left: 15px;
}
.App_form-footer__1Bcl1 {
  margin-top: 10%;
}
.App_form-footer__1Bcl1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /* Gray / Cool Gray / 500 */

  color: var(--gray-500);
}

.App_form-wrapper__2qpMs {
  padding: 10% 3%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  min-width: 250px;
}


@media (min-width:250px) {
  .App_form-wrapper__2qpMs {
    width: 100%;
  }
}

@media (min-width:320px) {
  .App_form-wrapper__2qpMs {
    width: 90%;
  }
}
@media (min-width:480px) {
  .App_form-wrapper__2qpMs {
    width: 70%;
  }
}
@media (min-width:600px) {
  .App_form-wrapper__2qpMs {
    width: 60%;
  }
}

@media (min-width:801px) {
  .App_form-wrapper__2qpMs {
    width: 42%;
  }
}

@media (min-width:1025px) {
  .App_form-wrapper__2qpMs {
    width: 42%;
  }
}

@media (min-width:1281px) {
}

.App_form-wrapper__2qpMs .App_b-form-body__3c1Ak {
  border: 1px solid var(--gray-400);
  border-radius: 6px;
  margin-bottom: 16px;
}
.App_form-wrapper__2qpMs .App_b-form-body__3c1Ak .App_custom-form-group__1qpQS {
  margin-bottom: 0;
}
.App_form-wrapper__2qpMs .App_b-form-body__3c1Ak .App_custom-form-group__1qpQS {
  display: flex;
  background-color: white;
  flex-direction: column;
  height: 56px;
  border-radius: 6px 6px 0px 0px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid var(--gray-400);
  margin-top: 2px;
}

.App_form-wrapper__2qpMs .App_b-form-body__3c1Ak .App_custom-form-group__1qpQS:last-child {
  border-bottom: 0;
  border-radius: 6px;
}
.App_form-wrapper__2qpMs .App_b-form-body__3c1Ak .App_custom-form-group__1qpQS .App_form-custom-label__1GKBD {
  padding-top: 8px;
  padding-left: 12px;
  text-align: left;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* Black / Dark Black / 800 */
  color: var(--dark-black-800);
}
.App_form-wrapper__2qpMs .App_b-form-body__3c1Ak .App_custom-form-group__1qpQS input {
  background-color: transparent;
  border: none;
  height: calc(0.5em + 0.75rem + 2px);
}
.App_form-wrapper__2qpMs .App_b-form-body__3c1Ak .App_form-names-container__2_9jZ {
  display: flex;
  flex-direction: row;
}
.App_form-wrapper__2qpMs .App_b-form-body__3c1Ak .App_form-names-container__2_9jZ .App_custom-form-group__1qpQS {
  flex: 1 1;
}
.App_form-wrapper__2qpMs
  .App_b-form-body__3c1Ak
  .App_form-names-container__2_9jZ
  .App_custom-form-group__1qpQS:nth-child(2) {
  border-left: 1px solid var(--gray-400);
  border-bottom: 1px solid var(--gray-400);
  border-radius: 0;
}
.App_form-wrapper__2qpMs .App_remember-me-wrapper__2qhqN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
}
.App_form-wrapper__2qpMs .App_custom-link__2rMdV {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* Black / Dark Black / 800 */
  color: var(--dark-black-800);
}
.App_form-wrapper__2qpMs .App_custom-link-blue__2fyig {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* Secondary / Eastern-Blue / 500 */
  color: var(--easter-blue-500);
}
form .App_remember-me-wrapper__2qhqN .App_form-check__1wK4h {
  padding: 20px !important;
}
.App_remember-me-wrapper__2qhqN .App_remember-me-check-container__2Bs5B {
  display: flex;
}
.App_remember-me-wrapper__2qhqN .App_remember-me-check-container__2Bs5B .App_custom-checkbox__1gGLu {
  padding-left: 0;
}

.App_form-header-container__3Csn5 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.App_form-header-container__3Csn5 img {
  width: 348px;
}
.App_form-header-container__3Csn5 .App_form-header-title__3i6MT {
  /* Title 36px */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height, or 111% */
  display: flex;
  align-items: flex-end;
  /* Black / Dark Black / 800 */
  color: #121212;
  padding: 24px 0 40px 0;
}

.App_form-headline__3piCi {
  text-align: center;
  padding: 10px 0;
  font-weight: 200;
}
.App_text__12ZO1 {
  text-align: center;
  padding: 15px;
  /* Body/Regular/14px */
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  /* Gray / Cool Gray / 500 */
  color: var(--gray-500);
}
.App_error-message__1wFVZ {
  background-color: rgb(255, 146, 146);
  padding: 10px;
  height: auto;
  margin: 10px 0;
}
.App_rounded-input__DWd5T {
  /*width: 410px;*/
  width: 100%;
  height: 48px;
  border-radius: 6px;
  background-color: var(--gray-100);
  border: 0;
  padding-left: 12px;
}
.App_search-container__DJksT {
  position: relative;
}
.App_search-container__DJksT .App_filler__1XR4j {
  flex: 1 1;
}
.App_search-container__DJksT .App_search-icon__29PZC {
  position: absolute;
  right: 30px;
  top: 12px;
}
.App_custom-modal-header__2-mC2 {
  border: 0;
  display: flex;
  justify-content: center;
}
.App_custom-modal-header__2-mC2 .App_custom-modal-title__2obae {
  text-align: center;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
}
.App_terms-and-conditions__ZlAW6 {
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.App_terms-and-conditions-clause-title__NZNIP {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
  padding-bottom: 5px;
}
.App_terms-and-conditions-clause-content__2HKWe {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* Gray / Cool Gray / 500 */
  color: var(--gray-500);
  margin-bottom: 32px;
}
.App_terms-and-conditions-footer__3wBAW {
  display: flex;
  justify-content: center;
}
.App_terms-and-conditions-actions__385uY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 16px 16px;
}
.App_terms-and-conditions-actions__385uY div {
  margin-left: auto;
}
.App_tac-checkbox__JQ2lc {
  display: flex;
  padding: 10px;
  align-items: center;
}
.App_tac-checkbox__JQ2lc label {
  /* Body/Regular/14px */
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* Gray / Cool Gray / 400 */
  color: var(--gray-400);
}

:root {
  --easter-blue-50: #f4fafb;
  --easter-blue-100: #e9f5f8;
  --easter-blue-200: #c8e6ed;
  --easter-blue-300: #a7d7e2;
  --easter-blue-400: #65bacc;
  --easter-blue-500: #239cb6;
  --easter-blue-600: #208ca4;
  --easter-blue-700: #1a7589;
  --easter-blue-800: #155e6d;
  --easter-blue-900: #114c59;
  --gray-color: #6b7280;
  --gray-50:#F9FAFB;
  --gray-100: #f3f4f6;
  --gray-200:#E5E7EB;
  --gray-400:#9CA3AF;
  --gray-500:#6B7280;
  --gray-800:#1F2937;
  
  --tan-hide-100:#FEF5F0;
  --tan-hide-500:#F89F64;
  --primary-cinnabar-100:#FEEDED;
  --alert-complete-500:#34D399;

  --dark-black-800:#121212;
  
}

.Dashboard_dashboard-container__11kFz {
  flex: 1 1;
  background-color: var(--easter-blue-100);
  border-radius: 48px;
  padding: 32px;
}
.Dashboard_dashboard-main-content__2eHgv {
  background-color: white;
  border-radius: 48px;
  padding: 36px 32px;
}

.Dashboard_header-container__RhYtL {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--gray-200);
}
.Dashboard_header-container__RhYtL .Dashboard_title__3N-_V {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Dashboard_header-container__RhYtL .Dashboard_title__3N-_V > h3 {
  margin: 0 5px 0 0;
}
.Dashboard_username-settings__1kr2L {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color:var(--easter-blue-800);
  text-align: center;
  padding-right: 20px;
  word-break: break-all;
}
.Dashboard_header-container__RhYtL > .Dashboard_title__3N-_V {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  color: var(--gray-color);
  margin-bottom: 0
}
.Dashboard_header-container__RhYtL > .Dashboard_title__3N-_V > h3 span {
  color: var(--dark-black-800) !important;
}
.Dashboard_header-container__RhYtL .Dashboard_title__3N-_V > span {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: var(--gray-800);
}
.Dashboard_user-settings-box__AIX3W {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.Dashboard_user-settings-box__AIX3W > div {
  padding: 10px 0;
  margin-right: 10px;
}
.Dashboard_search-bar__2ARhn {
  display: flex;
  justify-content: space-between;
  flex-grow: 2;
}
.Dashboard_notifications-list-box__22P3N {
  position: absolute;
  z-index: 1;
}
.Dashboard_notifications__2zG1B {
  position: absolute;
  top: -10px;
  right: -5px;
  background-color: red;
  padding: 4px;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  font-size: 12px;
  color: white;
}
.Dashboard_notification-icon__2u__z {
  cursor: pointer;
  color: var(--tan-hide-500);
}
.Dashboard_notification-item__3ihzk {
  list-style: none;
}
.Dashboard_notifications-list-box__22P3N ul li:hover {
  background-color: gray;
  color: rebeccapurple;
}
.Dashboard_notification-wrapper__2z_M7{
  position: relative;
}
.Dashboard_notification-wrapper__2z_M7 .Dashboard_icon-wrapper__2CeK-{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tan-hide-100);
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.user-setting-menu-container {
    margin-top: 5px;
    padding:24px;
    position: absolute;
    background-color: white;
    width: 326px;
    right: 2.3rem;
    z-index: 2;
    border: 1px solid #F3F4F6;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

}

.user-menu-content > ul:first-child > li:first-child:hover{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom:1px solid var(--easter-blue-500);
}

.user-menu-content > ul:first-child > li:first-child{
    border-bottom: 1px solid #F3F4F6;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.user-menu-content{
    border: 1px solid #F3F4F6;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 16px 0;
    padding: 5px 10px;
}

.user-certificate-setting-card:hover{
    border-color: transparent !important;
}

.btn-view-certificate{
    padding: 8px;
    height: auto;
}

.user-certificate-setting-card{
    padding: 8px;
    min-height:0;
    min-height:initial;
    max-height:none;
    max-height:initial;
    background: #F3F4F6 !important;
    box-shadow: inset 2px 0 0 #F89F64;
    border-radius: 0 8px 8px 0;
}

.user-setting-menu-container .user-name{
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    /* Gray / Cool Gray / 800 */
    color: var(--gray-800);
}
.user-setting-menu-container .user-email{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
}
.user-setting-menu-container .user-menu-header{
    padding: 10px;
}

.sidebar {
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    padding-top: 60px;
    text-align: left;

}
.overview-sidebar-header {
    font-size: 36px;
    font-weight: bold;
    border-bottom: 1px solid gray;
    width: 98%;
}

.sidebar-mobile{

}

.sidebar-mobile .navbar-toggler.open{
    right: 0;
    top: 0;
    position: relative;
}

.sidebar-mobile .navbar-toggler,
.sidebar-mobile .navbar-toggler:not(.open){
    left: 52px;
    padding: 2px 5px;
}

.sidebar-mobile .btn-toggle-wrapper{
    right: -35px;
    position: relative;
}

.bounce-navbar-nav .bounce-wrapper{
    transition: opacity 2.5s ease;
    opacity: 1;
}
.bounce-navbar-nav{
    overflow-y: scroll;
    overflow-x: hidden;
    left: 0;
    position: fixed;
    padding: 10px;
    background-color: #fff;
    top: 0;
    z-index: 9;
    bottom: 0;

    transition: width .3s ease, min-width .3s ease;

    min-width: 256px;
    width: 256px;
}

.bounce-navbar-nav .bounce-wrapper.collapsed{
    opacity: 0;
}

.bounce-navbar-nav.collapsed{
    min-width: 0;
    width: 0;
    padding: 0;
    overflow: hidden;
}


/*Media Query*/

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
    .sidebar-mobile .navbar-toggler:not(.open){
        left: 48px;
        top: -1px;
    }
}


.module-header-container {
  margin-top: 32px;
  display: flex;
  padding: 24px;
  background-color: var(--tan-hide-100);
  border-radius: 8px;
  flex-wrap: wrap;
}
.module-header-container img {
  width: 31px;
  height: 31px;
}
.module-header-container .image-wrapper {
  width: 56px;
  height: 56px;
  padding: 12.5px;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-right: 20px;
}
.module-header-container .module-titles-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.module-header-container .module-titles-container .title {
  /* Body/Semi Bold / 14px */

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-800);
}
.module-header-container .module-titles-container .description {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1f2937;
}

.module-header-container .module-header-btn {
}

.video-description-header {
    display: flex;
    justify-content: flex-end;
}
.video-description-header .custom-rating li:last-child {
    margin-right: 0px;
}
.video-description-header .custom-rating li {
    font-size: 20px;
}
.video-description-container .video-description {
    /* Body/Regular/14px */
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: 8px 12px;
    /* White / 500 */
    background: #FFFFFF;
    /* Gray / Cool Gray / 200 */
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    border-radius: 6px;
    margin: 20px 0;
}
.video-description-container .video-description .description{
    text-align: left;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* Gray / Cool Gray / 500 */
    color: #6B7280;
}
.section-home-container .video-title{
    text-align: left;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
}
.knowledge-check-container {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  background: var(--primary-cinnabar-100);
  border-radius: 12px;
  flex-wrap: wrap;
}
.knowledge-check-container .left-container {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
}
.knowledge-check-container .left-container .title {
  padding-left: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  text-align: center;

  /* Gray / Cool Gray / 800 */

  color: #1f2937;
}
.knowledge-check-container .left-container .certification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* White / 500 */
  width: 56px;
  height: 56px;
  background: #ffffff;
  /* Modal - shadow */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.knowledge-check-container .text-container .title {
  font-size: 1.5rem;
  font-weight: bold;
}
.knowledge-check-container .text-container .subtitle {
  font-size: 1.2rem;
}
.knowledge-check-container .knowledge-check-start-btn {
  font-size: 1.2rem;
  font-weight: bold;
  width: 15%;
}
.knowledge-check-list-container {
  text-align: left;
}

.section-container {
  margin: 20px 0;
}
.section-container .section-item-body {
  background: var(--gray-50);
  border-radius: 12px;
}
.section-header-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.section-container.accordion .card {
  background: #ffffff;
  /* Gray / Cool Gray / 200 */
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  margin: 20px 0;
}
.section-container.accordion .card .card-header {
  background: #ffffff;
  border-bottom: 0px;
  padding: 0;
}
.section-header-container .section-name {
  display: flex;
  align-items: center;
  /* Body/Semi Bold / 18px */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
}
.section-header-container .section-score {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 0 0 11px;
  font-size: 16px;
}
.section-header-container .section-score span {
  padding-left: 8px;
}
.section-header-container .section-score::after {
  content: "+";
  font-size: 24px;
  padding: 0 21px 8px 22px;
  color: var(--gray-400);
}
.section-header-container .section-score.active::after {
  content: "-";
  font-size: 24px;
  padding: 0 21px 8px 22px;
  color: var(--gray-400);
}
.section-header-container .section-score .score {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--alert-complete-500);
}
.section-header-container .section-header {
  display: flex;
  flex-direction: row;
}
.section-header-container .section-header div {
  padding-left: 10px;
}
.section-header-container .section-progress-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.section-header-container .section-progress-container .section-progress {
  flex-grow: 1;
  flex-direction: row;
}
.section-header-container
  .section-progress-container
  .section-progress.progress {
  height: 6px;
}
.section-header-container
  .section-progress-container
  .section-progress.progress
  .progress-bar {
  background-color: var(--easter-blue-500);
  border-radius: 50px;
  height: 6px;
}

