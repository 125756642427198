@import "./colors.css";

.dashboard-container {
  flex: 1;
  background-color: var(--easter-blue-100);
  border-radius: 48px;
  padding: 32px;
}
.dashboard-main-content {
  background-color: white;
  border-radius: 48px;
  padding: 36px 32px;
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--gray-200);
}
.header-container .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-container .title > h3 {
  margin: 0 5px 0 0;
}
.username-settings {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color:var(--easter-blue-800);
  text-align: center;
  padding-right: 20px;
  word-break: break-all;
}
.header-container > .title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  color: var(--gray-color);
  margin-bottom: 0
}
.header-container > .title > h3 span {
  color: var(--dark-black-800) !important;
}
.header-container .title > span {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: var(--gray-800);
}
.user-settings-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.user-settings-box > div {
  padding: 10px 0;
  margin-right: 10px;
}
.search-bar {
  display: flex;
  justify-content: space-between;
  flex-grow: 2;
}
.notifications-list-box {
  position: absolute;
  z-index: 1;
}
.notifications {
  position: absolute;
  top: -10px;
  right: -5px;
  background-color: red;
  padding: 4px;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  font-size: 12px;
  color: white;
}
.notification-icon {
  cursor: pointer;
  color: var(--tan-hide-500);
}
.notification-item {
  list-style: none;
}
.notifications-list-box ul li:hover {
  background-color: gray;
  color: rebeccapurple;
}
.notification-wrapper{
  position: relative;
}
.notification-wrapper .icon-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tan-hide-100);
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
