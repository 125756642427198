.default-button {
  height: 40px;
  background-color: #007bff;
  width: 100%;
}
.warning-button {
  height: 40px;
  background-color: #c72b62;
  width: 100%;
  border: none;
}
.terms-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 15px 0;
}
.terms-and-conditions {
  text-align: left;
  padding-left: 15px;
}
.form-footer {
  margin-top: 10%;
}
.form-footer p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  /* Gray / Cool Gray / 500 */

  color: var(--gray-500);
}

.form-wrapper {
  padding: 10% 3%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  min-width: 250px;
}


@media (min-width:250px) {
  .form-wrapper {
    width: 100%;
  }
}

@media (min-width:320px) {
  .form-wrapper {
    width: 90%;
  }
}
@media (min-width:480px) {
  .form-wrapper {
    width: 70%;
  }
}
@media (min-width:600px) {
  .form-wrapper {
    width: 60%;
  }
}

@media (min-width:801px) {
  .form-wrapper {
    width: 42%;
  }
}

@media (min-width:1025px) {
  .form-wrapper {
    width: 42%;
  }
}

@media (min-width:1281px) {
}

.form-wrapper .b-form-body {
  border: 1px solid var(--gray-400);
  border-radius: 6px;
  margin-bottom: 16px;
}
.form-wrapper .b-form-body .custom-form-group {
  margin-bottom: 0;
}
.form-wrapper .b-form-body .custom-form-group {
  display: flex;
  background-color: white;
  flex-direction: column;
  height: 56px;
  border-radius: 6px 6px 0px 0px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid var(--gray-400);
  margin-top: 2px;
}

.form-wrapper .b-form-body .custom-form-group:last-child {
  border-bottom: 0;
  border-radius: 6px;
}
.form-wrapper .b-form-body .custom-form-group .form-custom-label {
  padding-top: 8px;
  padding-left: 12px;
  text-align: left;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* Black / Dark Black / 800 */
  color: var(--dark-black-800);
}
.form-wrapper .b-form-body .custom-form-group input {
  background-color: transparent;
  border: none;
  height: calc(0.5em + 0.75rem + 2px);
}
.form-wrapper .b-form-body .form-names-container {
  display: flex;
  flex-direction: row;
}
.form-wrapper .b-form-body .form-names-container .custom-form-group {
  flex: 1;
}
.form-wrapper
  .b-form-body
  .form-names-container
  .custom-form-group:nth-child(2) {
  border-left: 1px solid var(--gray-400);
  border-bottom: 1px solid var(--gray-400);
  border-radius: 0;
}
.form-wrapper .remember-me-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
}
.form-wrapper .custom-link {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* Black / Dark Black / 800 */
  color: var(--dark-black-800);
}
.form-wrapper .custom-link-blue {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* Secondary / Eastern-Blue / 500 */
  color: var(--easter-blue-500);
}
form .remember-me-wrapper .form-check {
  padding: 20px !important;
}
.remember-me-wrapper .remember-me-check-container {
  display: flex;
}
.remember-me-wrapper .remember-me-check-container .custom-checkbox {
  padding-left: 0;
}

.form-header-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.form-header-container img {
  width: 348px;
}
.form-header-container .form-header-title {
  /* Title 36px */
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height, or 111% */
  display: flex;
  align-items: flex-end;
  /* Black / Dark Black / 800 */
  color: #121212;
  padding: 24px 0 40px 0;
}

.form-headline {
  text-align: center;
  padding: 10px 0;
  font-weight: 200;
}
.text {
  text-align: center;
  padding: 15px;
  /* Body/Regular/14px */
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  /* Gray / Cool Gray / 500 */
  color: var(--gray-500);
}
.error-message {
  background-color: rgb(255, 146, 146);
  padding: 10px;
  height: auto;
  margin: 10px 0;
}
.rounded-input {
  /*width: 410px;*/
  width: 100%;
  height: 48px;
  border-radius: 6px;
  background-color: var(--gray-100);
  border: 0;
  padding-left: 12px;
}
.search-container {
  position: relative;
}
.search-container .filler {
  flex: 1;
}
.search-container .search-icon {
  position: absolute;
  right: 30px;
  top: 12px;
}
.custom-modal-header {
  border: 0;
  display: flex;
  justify-content: center;
}
.custom-modal-header .custom-modal-title {
  text-align: center;
  border: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
}
.terms-and-conditions {
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.terms-and-conditions-clause-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
  padding-bottom: 5px;
}
.terms-and-conditions-clause-content {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* Gray / Cool Gray / 500 */
  color: var(--gray-500);
  margin-bottom: 32px;
}
.terms-and-conditions-footer {
  display: flex;
  justify-content: center;
}
.terms-and-conditions-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 16px 16px;
}
.terms-and-conditions-actions div {
  margin-left: auto;
}
.tac-checkbox {
  display: flex;
  padding: 10px;
  align-items: center;
}
.tac-checkbox label {
  /* Body/Regular/14px */
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* Gray / Cool Gray / 400 */
  color: var(--gray-400);
}
