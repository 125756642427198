.module-header-container {
  margin-top: 32px;
  display: flex;
  padding: 24px;
  background-color: var(--tan-hide-100);
  border-radius: 8px;
  flex-wrap: wrap;
}
.module-header-container img {
  width: 31px;
  height: 31px;
}
.module-header-container .image-wrapper {
  width: 56px;
  height: 56px;
  padding: 12.5px;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-right: 20px;
}
.module-header-container .module-titles-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.module-header-container .module-titles-container .title {
  /* Body/Semi Bold / 14px */

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-800);
}
.module-header-container .module-titles-container .description {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1f2937;
}

.module-header-container .module-header-btn {
}
