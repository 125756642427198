.knowledge-check-container {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  background: var(--primary-cinnabar-100);
  border-radius: 12px;
  flex-wrap: wrap;
}
.knowledge-check-container .left-container {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
}
.knowledge-check-container .left-container .title {
  padding-left: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  text-align: center;

  /* Gray / Cool Gray / 800 */

  color: #1f2937;
}
.knowledge-check-container .left-container .certification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* White / 500 */
  width: 56px;
  height: 56px;
  background: #ffffff;
  /* Modal - shadow */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.knowledge-check-container .text-container .title {
  font-size: 1.5rem;
  font-weight: bold;
}
.knowledge-check-container .text-container .subtitle {
  font-size: 1.2rem;
}
.knowledge-check-container .knowledge-check-start-btn {
  font-size: 1.2rem;
  font-weight: bold;
  width: 15%;
}
.knowledge-check-list-container {
  text-align: left;
}
