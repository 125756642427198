.user-setting-menu-container {
    margin-top: 5px;
    padding:24px;
    position: absolute;
    background-color: white;
    width: 326px;
    right: 2.3rem;
    z-index: 2;
    border: 1px solid #F3F4F6;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

}

.user-menu-content > ul:first-child > li:first-child:hover{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom:1px solid var(--easter-blue-500);
}

.user-menu-content > ul:first-child > li:first-child{
    border-bottom: 1px solid #F3F4F6;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.user-menu-content{
    border: 1px solid #F3F4F6;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 16px 0;
    padding: 5px 10px;
}

.user-certificate-setting-card:hover{
    border-color: transparent !important;
}

.btn-view-certificate{
    padding: 8px;
    height: auto;
}

.user-certificate-setting-card{
    padding: 8px;
    min-height:initial;
    max-height:initial;
    background: #F3F4F6 !important;
    box-shadow: inset 2px 0 0 #F89F64;
    border-radius: 0 8px 8px 0;
}

.user-setting-menu-container .user-name{
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    /* Gray / Cool Gray / 800 */
    color: var(--gray-800);
}
.user-setting-menu-container .user-email{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
}
.user-setting-menu-container .user-menu-header{
    padding: 10px;
}
