.sidebar {
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    padding-top: 60px;
    text-align: left;

}
.overview-sidebar-header {
    font-size: 36px;
    font-weight: bold;
    border-bottom: 1px solid gray;
    width: 98%;
}

.sidebar-mobile{

}

.sidebar-mobile .navbar-toggler.open{
    right: 0;
    top: 0;
    position: relative;
}

.sidebar-mobile .navbar-toggler,
.sidebar-mobile .navbar-toggler:not(.open){
    left: 52px;
    padding: 2px 5px;
}

.sidebar-mobile .btn-toggle-wrapper{
    right: -35px;
    position: relative;
}

.bounce-navbar-nav .bounce-wrapper{
    transition: opacity 2.5s ease;
    opacity: 1;
}
.bounce-navbar-nav{
    overflow-y: scroll;
    overflow-x: hidden;
    left: 0;
    position: fixed;
    padding: 10px;
    background-color: #fff;
    top: 0;
    z-index: 9;
    bottom: 0;

    transition: width .3s ease, min-width .3s ease;

    min-width: 256px;
    width: 256px;
}

.bounce-navbar-nav .bounce-wrapper.collapsed{
    opacity: 0;
}

.bounce-navbar-nav.collapsed{
    min-width: 0;
    width: 0;
    padding: 0;
    overflow: hidden;
}


/*Media Query*/

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
    .sidebar-mobile .navbar-toggler:not(.open){
        left: 48px;
        top: -1px;
    }
}

