:root {
  --easter-blue-50: #f4fafb;
  --easter-blue-100: #e9f5f8;
  --easter-blue-200: #c8e6ed;
  --easter-blue-300: #a7d7e2;
  --easter-blue-400: #65bacc;
  --easter-blue-500: #239cb6;
  --easter-blue-600: #208ca4;
  --easter-blue-700: #1a7589;
  --easter-blue-800: #155e6d;
  --easter-blue-900: #114c59;
  --gray-color: #6b7280;
  --gray-50:#F9FAFB;
  --gray-100: #f3f4f6;
  --gray-200:#E5E7EB;
  --gray-400:#9CA3AF;
  --gray-500:#6B7280;
  --gray-800:#1F2937;
  
  --tan-hide-100:#FEF5F0;
  --tan-hide-500:#F89F64;
  --primary-cinnabar-100:#FEEDED;
  --alert-complete-500:#34D399;

  --dark-black-800:#121212;
  
}
