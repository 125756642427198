.video-description-header {
    display: flex;
    justify-content: flex-end;
}
.video-description-header .custom-rating li:last-child {
    margin-right: 0px;
}
.video-description-header .custom-rating li {
    font-size: 20px;
}
.video-description-container .video-description {
    /* Body/Regular/14px */
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: 8px 12px;
    /* White / 500 */
    background: #FFFFFF;
    /* Gray / Cool Gray / 200 */
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    border-radius: 6px;
    margin: 20px 0;
}
.video-description-container .video-description .description{
    text-align: left;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* Gray / Cool Gray / 500 */
    color: #6B7280;
}
.section-home-container .video-title{
    text-align: left;
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
}