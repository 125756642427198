body,
html {
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
  background: #f9fafb;
  border-radius: 48px;
  bottom: 0;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.menu {
  padding: 0;
  list-style-type: none;
}
.menu.isFlatMenu {
  padding: 0;
  list-style-type: none;
  text-align: left;
}
.menu-item {
  height: auto;
  min-height: 42px;
  max-height: 84px;
  background-color: var(--easter-blue-200);
  border-radius: 8px;
  padding: 8px 8px 8px 12px;
  margin: 9px 0;
}

li.menu-item:hover{
  border: 1px solid var(--easter-blue-500);
}

li.menu-item{
  border: 1px solid transparent;
}

.menu-item-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: var(--easter-blue-500);
}
.menu-item.expanded {
  background-color: var(--easter-blue-400);
}
.menu-item.expanded.isFlatMenu .menu-item-title {
  background-color: white;
  color: #282c34;
}
.menu-item.isFlatMenu {
  background-color: white;
}
.menu-item.isFlatMenu .menu-item-title {
  color: #282c34;
}
.menu-item.expanded .menu-item-title {
  color: #ffffff;
}
.menu-item.hasSubmenu > .menu-item-title {
  font-weight: bold;
}
.menu .menu .menu-item.hasSubmenu > .menu-item-title {
  font-weight: normal;
}
.menu-item.hasSubmenu.expanded.isModuleOpen {
  background-color: var(--tan-hide-500);
}
.menu.isModuleOpen {
  border-left: 3px solid var(--tan-hide-500);
}
.menu.isModuleOpen > .menu-item {
  padding: 8px 8px 8px 28px;
  /*height: 40px;*/
  height: auto;
  /* Secondary / Eastern-Blue / 100 */
  background: var(--easter-blue-100);
}
.menu-item.hasSubmenu.isFlatMenu > .menu-item-title {
  /* Body/Regular / 16px */
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
}
.menu-item.expanded.hasSubmenu.isSectionOpen {
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
  background-color: var(--easter-blue-400);
  border-left:none;
}

.hasSubmenu > .menu-item-title::after {
  content: " + ";
  padding: 0 12px;
  float: right;
  font-size: 21px;
}

.expanded.hasSubmenu > .menu-item-title::after {
  content: " — ";
  padding: 0 12px;
  float: right;
  font-size: 21px;
}
.menu .menu {
  padding-left: 0;
  margin-left: 8px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-button-default:hover {
  background: rgb(241, 137, 67);
}

.custom-button-default {
  /* Body/Semi Bold / 16px */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #f89f64;
  border-radius: 6px;
  border: 0px;
  padding: 12px;
  width: 100%;
  height: 48px;
  position: relative;
}
.b-custom-button-default {
  /* Body/Semi Bold / 16px */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #f89f64;
  border-radius: 6px;
  border: 0px;
  padding: 12px;
  height: 48px;
}

.custom-button-default.disabled,
.b-custom-button-default.disabled {
  background: #fae3d3;
}
.custom-button-white {
  /* Body/Semi Bold / 16px */
  background: #ffffff;
  /* Gray / Cool Gray / 200 */
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  /* Modal - shadow */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.custom-button-white img {
  padding: 0 10px;
}
.custom-button-blue {
  /* Secondary / Eastern-Blue / 100 */
  width: 100%;
  background: #e9f5f8;
  border-radius: 8px;
  border: 0px;
  padding: 8px 16px;

  /* Body/Semi Bold / 16px */

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  /* Secondary / Eastern-Blue / 800 */

  color: #155e6d;
}

.custom-button-danger {
  /* Body/Semi Bold / 16px */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #f0494a;
  border-radius: 6px;
  border: 0px;
  padding: 16px;
}

.custom-label-text {
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #111827;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.custom-label-description {
  position: static;
  width: 366px;
  height: 20px;
  left: 80px;
  top: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #1f2937;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 12px;
}

.custom-question-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: center;
  flex-direction: row;
  align-items: center;
  padding: 8px;
}
.custom-label-question {
  color: #1f2937;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.custom-label-question-number {
  color: #9ca3af;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.custom-button-back svg{
  margin-right: 10px;
}

.custom-button-back:hover {
  background-color: #f6f5f5;
}

.custom-button-back {
  /*height: 48px;*/
  left: 130px;
  right: 385px;
  top: 201px;

  /* Gray / Cool Gray / 100 */

  /*background: #f3f4f6;*/
  background: #fff;
  /* Black / Dark Black / 800 */

  border: 1px solid #121212;
  box-sizing: border-box;
  border-radius: 6px;

  padding: 11px 25px;
}

.custom-question-answers {
  padding: 0px;
  position: static;
  left: 0px;
  top: 0px;
  background: #f4fafb;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
  display: flex;
}

.custom-submit-container {
  display: flex;
  justify-content: flex-end;
}

.custom-submit-container-modal {
  justify-content: flex-end;
}

.custom-submit-button:hover {
  background: rgb(241, 137, 67);
}

.custom-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 87px;
  height: 48px;
  /* Complement / Tan-Hide / 500 */
  border: none;
  background: #f89f64;
  border-radius: 6px;
  margin-bottom: 0;
  cursor: pointer;
}

.custom-submit-button-retake {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 87px;
  height: 48px;
  /* Complement / Tan-Hide / 500 */
  border: none;
  background: #f89f64;
  border-radius: 6px;
  margin-left: 10px;
}

.custom-submit-button-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  height: 48px;
  /* Complement / Tan-Hide / 500 */
  border: none;
  background: #f89f64;
  border-radius: 6px;
}

.modal-success-number-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;

  position: static;
  width: 88px;
  height: 48px;
  left: 59px;
  top: 0px;

  background: #dafff1;
  border-radius: 8px;
}
.modal-success-number-text {
  text-align: center;

  color: #13b77b;
}

.modal-fail-number-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;

  position: static;
  width: 88px;
  height: 48px;
  left: 59px;
  top: 0px;

  background: #feeded;
  border-radius: 8px;
}
.modal-fail-number-text {
  text-align: center;

  color: #f0494a;
}
.modal-success-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */
  text-align: center;
  /* gray/900 */
  color: var(--gray-900);
}
.modal-container-success {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  position: static;
}
.custom-submit-button-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
}

.custom-label-button-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  align-items: flex-end;
  position: static;
  width: 68px;
  height: 40px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 12px;
}

input[type="radio"] {
  visibility: hidden;
  position: relative;

  margin-left: 5px;
  width: 20px;
  height: 20px;
}

input[type="radio"]:before {
  content: "";
  visibility: visible;
  content: "";
  background: #ffffff;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

input[type="radio"]:checked:before {
  background-color: #239cb6;
  box-shadow: inset 0 0 0 4px #ffffff;
  border: 1.5px solid #239cb6;
  box-sizing: border-box;
  border-radius: 50px;
}

input[type="checkbox"] {
  visibility: hidden;
  position: relative;

  margin-left: 5px;
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:before {
  content: "";
  visibility: visible;
  content: "";
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

input[type="checkbox"]:checked:before {
  background-color: #239cb6;
  box-shadow: inset 0 0 0 4px #ffffff;
  border: 1.5px solid #239cb6;
  box-sizing: border-box;
  border-radius: 6px;
}

.custom-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-spinner-container span {
  padding-left: 5px;
}

.btn-back span{
  margin-left: 15px;
}

.btn-back{
  border: none;
  background-color: transparent;
}

@media (max-width:250px) {
  .App {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width:320px) {
  .App {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width:480px) {
  .App {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width:600px) {
  .App {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@media (min-width:801px) {
}


.bg-transparent{
  background: transparent !important;
}


.loading {
  z-index: 999;
  position: fixed;
  top: 0; right: 0;
  bottom: 0; left: 0;
  background-color: rgba(0,0,0,.3);
}
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: .8em solid rgba(218, 219, 223, 1);
  border-left: .8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
}
.loader, .loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
