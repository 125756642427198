.section-container {
  margin: 20px 0;
}
.section-container .section-item-body {
  background: var(--gray-50);
  border-radius: 12px;
}
.section-header-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.section-container.accordion .card {
  background: #ffffff;
  /* Gray / Cool Gray / 200 */
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
  margin: 20px 0;
}
.section-container.accordion .card .card-header {
  background: #ffffff;
  border-bottom: 0px;
  padding: 0;
}
.section-header-container .section-name {
  display: flex;
  align-items: center;
  /* Body/Semi Bold / 18px */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* Gray / Cool Gray / 800 */
  color: var(--gray-800);
}
.section-header-container .section-score {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 0 0 11px;
  font-size: 16px;
}
.section-header-container .section-score span {
  padding-left: 8px;
}
.section-header-container .section-score::after {
  content: "+";
  font-size: 24px;
  padding: 0 21px 8px 22px;
  color: var(--gray-400);
}
.section-header-container .section-score.active::after {
  content: "-";
  font-size: 24px;
  padding: 0 21px 8px 22px;
  color: var(--gray-400);
}
.section-header-container .section-score .score {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--alert-complete-500);
}
.section-header-container .section-header {
  display: flex;
  flex-direction: row;
}
.section-header-container .section-header div {
  padding-left: 10px;
}
.section-header-container .section-progress-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.section-header-container .section-progress-container .section-progress {
  flex-grow: 1;
  flex-direction: row;
}
.section-header-container
  .section-progress-container
  .section-progress.progress {
  height: 6px;
}
.section-header-container
  .section-progress-container
  .section-progress.progress
  .progress-bar {
  background-color: var(--easter-blue-500);
  border-radius: 50px;
  height: 6px;
}
